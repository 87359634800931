import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { summaryType } from '../types/summaryType'

export interface OrderState {
    orders: summaryType[]
    totalCount: number
    recentlyUpdatedOrder: summaryType | null
}

const initialOrderState: OrderState = {
    orders: [],
    totalCount: 0,
    recentlyUpdatedOrder: null,
}

export const OrderSlice = createSlice({
    name: 'order',
    initialState: initialOrderState,
    reducers: {
        updateOrders: (state, action: PayloadAction<OrderState>) => {
            state.orders = action.payload.orders
            state.totalCount = action.payload.totalCount

            if (action.payload.recentlyUpdatedOrder != null) {
                const foundIndex = state.orders.findIndex(x => x.id == action.payload.recentlyUpdatedOrder?.id)
                if (foundIndex != -1) {
                    state.orders[foundIndex] = action.payload.recentlyUpdatedOrder
                } else {
                    state.orders.push(action.payload.recentlyUpdatedOrder)
                }
            }
        },
        updateOrder: (state, action: PayloadAction<summaryType>) => {
            const foundIndex = state.orders.findIndex(x => x.id == action.payload.id)
            if (foundIndex != -1) {
                state.orders[foundIndex] = action.payload
            } else {
                state.orders.push(action.payload)
                state.orders = state.orders.sort((a, b) => {
                    return Number(b.id) - Number(a.id)
                })
            }
            state.recentlyUpdatedOrder = action.payload
        },
    },
})

export default OrderSlice.reducer
export const { updateOrders, updateOrder } = OrderSlice.actions
