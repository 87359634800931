import { createContext } from 'react'

const theme = {
    gTheme: '',
    sidebarCollapsed: true,
}

const themeContext = createContext(theme)

export const CountryProvider = themeContext.Provider

export default themeContext
