import { Button, Col, Modal, Row, Space } from 'antd'
import React, { useState, useEffect } from 'react'
import { kcInstancelogout } from '../../config/keycloak'
import { clearTab } from '../../one-tab-enforcer'
import env from '../../config/env'
import { t } from 'i18next'

const TimerPopup: React.FC = () => {
    const [isPopupVisible, setPopupVisible] = useState<boolean>(false)
    const [isLogout, setIsLogout] = useState<boolean>(false)

    let inactivityTimer: NodeJS.Timeout
    let popupTimer: NodeJS.Timeout

    const handleUserActivity = () => {
        clearTimeout(inactivityTimer)
        clearTimeout(popupTimer)

        inactivityTimer = setTimeout(() => {
            setPopupVisible(true)

            popupTimer = setTimeout(() => {
                setIsLogout(true)
                clearTab()
                localStorage.setItem('isSessionExpired', 'yes')
            }, env.sessionExpireTimer * 60 * 1000)
        }, env.inActivityTimer * 60 * 1000)
    }

    useEffect(() => {
        const activityEvents: string[] = ['mousemove', 'mousedown', 'keydown', 'scroll']

        activityEvents.forEach(event => {
            window.addEventListener(event, handleUserActivity)
        })

        return () => {
            activityEvents.forEach(event => {
                window.removeEventListener(event, handleUserActivity)
            })
        }
    }, [])

    return (
        <Modal
            title={isLogout ? t('SESSION_END_TITTLE') : t('SESSION_ABOUT_TO_END_MSG_TITTLE')}
            okText={false}
            closable={false}
            open={isPopupVisible}
            cancelText={''}
            footer={false}
            centered={true}
        >
            <Row gutter={[8, 8]} align={'middle'}>
                <Col span={24}>{isLogout ? t('SESSION_END_MSG') : t('SESSION_ABOUT_TO_END_MSG')}</Col>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {isLogout ? (
                        <Button type='primary' onClick={() => kcInstancelogout()}>
                            {t('CLOSE')}
                        </Button>
                    ) : (
                        <Space size={8}>
                            <Button type='primary' danger onClick={() => kcInstancelogout()}>
                                {t('SIGN_OUT')}
                            </Button>
                            <Button type='primary' onClick={() => setPopupVisible(false)}>
                                {t('STAY_SIGNED_IN')}
                            </Button>
                        </Space>
                    )}
                </Col>
            </Row>
        </Modal>
    )
}

export default TimerPopup
