interface ItemProps {
    label: string
    value: string
    width: number
    forceSingleLine?: boolean
}
interface StatusObjectType {
    [key: string]: StatusType
}
interface StatusType {
    color: string
    value: string
}
interface languageType {
    label: string
    value: string
}

export const fieldOptions: ItemProps[] = [
    {
        value: 'orderId',
        label: 'REF_ID',
        width: 80,
    },
    {
        value: 'orderTime',
        label: 'DEAL_TIME',
        width: 140,
    },
    {
        value: 'orderStatus',
        label: 'STATUS',
        width: 110,
    },
    {
        value: 'statusDescription',
        label: 'STATUS_DESCRIPTION',
        width: 100,
    },
    {
        value: 'ccyPair',
        label: 'CCY_PAIR',
        width: 90,
    },
    {
        value: 'side',
        label: 'Buy/Sell',
        width: 100,
    },
    {
        value: 'orderQuantity',
        label: 'ORDER_AMOUNT',
        width: 160,
        forceSingleLine: true,
    },
    {
        value: 'executedQuantity',
        label: 'FILLED_AMOUNT',
        width: 120,
        forceSingleLine: true,
    },
    {
        value: 'executedPrice',
        label: 'PRICE',
        width: 80,
        forceSingleLine: true,
    },
    {
        value: 'contraQuantity',
        label: 'CONTRA_AMOUNT',
        width: 130,
        forceSingleLine: true,
    },
    // {
    //     value: 'timeInForce',
    //     label: 'FILL_TYPE',
    //     width: 120,
    // },
    {
        value: 'valueDate',
        label: 'VALUE_DATE',
        width: 110,
    },
    {
        value: 'tenor',
        label: 'TENOR',
        width: 120,
    },
    {
        value: 'account',
        label: 'ACCOUNT',
        width: 100,
    },
    {
        value: 'userId',
        label: 'USER_ID',
        width: 100,
    },
    {
        value: 'userComment',
        label: 'REMARKS',
        width: 200,
        forceSingleLine: true,
    },
    {
        value: 'orderType',
        label: 'ORDER_TYPE',
        width: 100,
    },
]

export const DefaultselectedItems: string[] = [
    'orderId',
    'orderTime',
    'valueDate',
    'orderStatus',
    'ccyPair',
    'executedPrice',
    'contraQuantity',
    'orderQuantity',
]

export const DefaultselectedItemsTable: string[] = [
    'orderId',
    'orderTime',
    'orderStatus',
    'ccyPair',
    'executedQuantity',
    'executedPrice',
    'contraQuantity',
    'valueDate',
    'account',
]

export const ticketViewList: ItemProps[] = [
    {
        value: 'orderTime',
        label: 'DEAL_TIME',
        width: 200,
    },
    {
        value: 'tenor',
        label: 'TENOR',
        width: 120,
    },
    {
        value: 'account',
        label: 'ACCOUNT',
        width: 100,
    },
    {
        value: 'orderQuantity',
        label: 'ORDER_AMOUNT',
        width: 130,
    },
    {
        value: 'orderType',
        label: 'ORDER_TYPE',
        width: 100,
    },
    {
        value: 'timeInForce',
        label: 'FILL_TYPE',
        width: 120,
    },
    {
        value: 'executedQuantity',
        label: 'FILLED_AMOUNT',
        width: 120,
    },
    {
        value: 'limitPrice',
        label: 'LIMIT_PRICE',
        width: 120,
    },
    {
        value: 'valueDate',
        label: 'VALUE_DATE',
        width: 120,
    },
    {
        value: 'orderStatus',
        label: 'STATUS',
        width: 120,
    },
    {
        value: 'executedPrice',
        label: 'PRICE',
        width: 80,
    },
    {
        value: 'contraQuantity',
        label: 'CONTRA_AMOUNT',
        width: 130,
    },
    {
        value: 'userId',
        label: 'USER_ID',
        width: 100,
    },
    {
        value: 'statusDescription',
        label: 'STATUS_DESCRIPTION',
        width: 100,
    },
    {
        value: 'userComment',
        label: 'REMARKS',
        width: 200,
    },
]

export const statusArray: StatusObjectType = {
    REJECTED: {
        color: 'red',
        value: 'Rejected',
    },
    FILLED: {
        color: 'green',
        value: 'Filled',
    },
    NOT_FILLED: {
        color: 'orange',
        value: 'Not Filled',
    },
    PARTIALLY_FILLED: {
        color: 'blue',
        value: 'Partially Filled',
    },
    CANCELLED: {
        color: 'orange',
        value: 'Cancelled',
    },
    IN_PROGRESS: {
        color: 'default',
        value: 'In Progress',
    },
}

export const languages: languageType[] = [
    { value: 'en-US', label: 'English' },
    { value: 'cn', label: '简体中文' },
]
