import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

import { RateSlice } from './rateReducer'
import { OrderSlice } from './orderReducer'
import { TokenSlice } from './tokenReducer'

export const store = configureStore({
    reducer: {
        rates: RateSlice.reducer,
        orders: OrderSlice.reducer,
        tokenState: TokenSlice.reducer,
    },
})
export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch: () => typeof store.dispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
