import React, { useState } from 'react'
import { Button, Col, Divider, Layout, Modal, Row, Select, Space, Typography, notification } from 'antd'
// import { dark, light } from '../../../theme/Theme.styled'
// import { ExclamationCircleFilled } from '@ant-design/icons'
// import { DARK } from '../../constant/theme'
// import moon_solid from '../../assets/moon-solid.svg'
// import sun_solid from '../../assets/sun-solid.svg'
import logoutImg from '../../assets/logout.svg'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import { useTranslation } from 'react-i18next'
import { kcInstancelogout } from '../../../config/keycloak'
import { languages } from '../../../config/constant'

const { Header } = Layout
const { Text } = Typography

interface GHeaderType {
    loginName?: string
    theme: string
    onThemeToggle: SwitchChangeEventHandler
}

const GHeader = (props: GHeaderType) => {
    const { loginName, theme } = props

    const { i18n } = useTranslation()
    const { t } = useTranslation()
    const [logoutConfirm, setLogoutConfirm] = useState<boolean>(false)
    const localLanguage = localStorage.getItem('currentLanguage')
    const defaultLanguage = localLanguage ? localLanguage : 'en-US'

    const changeLanguage = async (newLanguange: string) => {
        try {
            if (i18n.language === newLanguange) return
            localStorage.setItem('currentLanguage', newLanguange)
            await i18n.changeLanguage(newLanguange)
        } catch (error) {
            notification.error({
                message: t('ERROR'),
                description: t('LANGUAGE_CHANGE_PROBLEM'),
            })
        }
    }

    return (
        <Header className='myfx-header'>
            <Row className='myfx-header-row' align={'middle'}>
                <Col xs={0} sm={4} md={4}></Col>
                <Col data-testid='logger-info-col-id' xs={21} sm={18} lg={19} className={`logger-info ${theme}`}>
                    <Select
                        popupClassName={theme}
                        defaultValue={defaultLanguage}
                        bordered={false}
                        onChange={changeLanguage}
                        options={languages}
                        style={{ fontSize: 16 }}
                    />
                    <Divider type='vertical' style={{ height: 30, borderColor: '#0a3d6e', marginLeft: 0 }} />
                    <Space direction='horizontal' size={5}>
                        <Text style={{ width: 60, fontSize: 16 }} strong>
                            {t('HELLO')}
                        </Text>
                        <Text className='name-ellipsis' style={{ fontSize: 16, textAlign: 'left' }} ellipsis={{ tooltip: loginName }}>
                            {loginName}
                        </Text>
                        <Text className='name-ellipsis-mobile' style={{ fontSize: 16 }} ellipsis={{ tooltip: loginName }}>
                            {loginName?.charAt(0)}
                        </Text>
                    </Space>

                    {/* <Switch
                        data-testid='theme-switch-id'
                        size='default'
                        defaultChecked={theme === DARK}
                        checkedChildren={<img src={sun_solid} alt='icon' />}
                        unCheckedChildren={<img src={moon_solid} alt='icon' />}
                        onChange={onThemeToggle}
                    /> */}
                </Col>
                <Col data-testid='logger-info-col-id' style={{ flex: 1 }} xs={3} sm={2} lg={1}>
                    <div className='myfx-logout' onClick={() => setLogoutConfirm(true)}>
                        <img src={logoutImg} alt='icon' />
                    </div>
                </Col>
            </Row>
            <Modal
                className={`myfx-modal buy ${theme}`}
                title={t('LOGOUT_CONFIRMATION')}
                okText={false}
                centered={true}
                closable={false}
                open={logoutConfirm}
                cancelText={''}
                footer={[
                    <Button key='cancel' danger type='primary' onClick={() => setLogoutConfirm(false)}>
                        {t('CANCEL')}
                    </Button>,
                    <Button key='submit' type='primary' onClick={() => kcInstancelogout()}>
                        {t('LOG_OUT')}
                    </Button>,
                ]}
            >
                {t('LOGOUT_CONFIRMATION_MESSAGE')}
            </Modal>
        </Header>
    )
}

export default GHeader
