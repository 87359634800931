import Keycloak, { KeycloakLogoutOptions } from 'keycloak-js'

export let kc_instance: Keycloak

export const setKeycloak = (instance: Keycloak) => {
    kc_instance = instance
    console.debug(kc_instance)
    return instance
}

export const getLoggedUsername = () => kc_instance?.tokenParsed?.name

export const getRoles = () => {
    const resAccess = kc_instance?.tokenParsed?.resource_access
    const backend = resAccess ? resAccess['myfx-backend'] : ''
    return backend ? backend.roles : []
}

export const getToken = (): Promise<string | undefined> => {
    return new Promise(resolve => {
        kc_instance
            .updateToken(60)
            .then(() => {
                localStorage.removeItem('isSessionExpired')
                resolve(kc_instance?.token)
            })
            .catch(() => {
                kc_instance.login()
            })
    })
}

export const getPreferredUsername = () => kc_instance?.tokenParsed?.preferred_username

export const getUserProfile = () => kc_instance?.tokenParsed?.userprofile

export const kcInstancelogout = () => {
    const options: KeycloakLogoutOptions = {
        redirectUri: window.location.origin,
    }
    localStorage.removeItem('isSessionExpired')
    kc_instance
        .updateToken(0)
        .then(() => {
            kc_instance.logout(options)
        })
        .catch(() => {
            kc_instance.login()
        })
}
