import { DARK } from '../component/constant/theme'
import { ThemeConfig } from 'antd/es/config-provider'

type AntdThemeData = {
    colorPrimary: string
    fontFamily: string
}
type OtheThemeData = {
    headerColor: string
    fontFamily: string
}

type ThemeData = AntdThemeData & OtheThemeData
export type GThemeData = { token: ThemeData }
const lightThemeData = {
    colorPrimary: '#0E4FAE',
    fontFamily: 'Avenir',
    fontSize: 13,
}

const darkThemeData = {
    colorPrimary: '#063f76',
    fontFamily: 'Avenir',
    fontSize: 13,
}

export const light: ThemeConfig = {
    token: lightThemeData,
    components: {
        Table: {
            colorTextHeading: lightThemeData.colorPrimary,
        },
        Layout: {
            colorBgHeader: '#c6d0d9',
        },
    },
}

export const dark: ThemeConfig = {
    token: darkThemeData,
    components: {
        Table: {
            colorTextHeading: darkThemeData.colorPrimary,
        },
        Layout: {
            colorBgHeader: '#0f2b53',
        },
    },
}

export const getDefaultTheme = (theme: string) => (theme === DARK ? dark : light)
