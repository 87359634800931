import React, { useState } from 'react'
import { Layout } from 'antd'
import { LIGHT, DARK } from '../constant/theme'
import GHeader from './component/GHeader'
import GSider from './component/GSider'
import { ConfigProvider } from 'antd'
import './index.css'
import { ThemeConfig } from 'antd/es/config-provider'

import GLayoutType from './model/GLayoutType'
import { dark, light } from '../../theme/Theme.styled'
import themeContext from '../../context/themeContext'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

const { Content, Footer } = Layout

function GLayout(props: GLayoutType) {
    const { loginName, children, defaultTheme, onThemeChange, themeConfig, ...rest } = props

    const { i18n } = useTranslation()
    const [collapsed, setCollapsed] = useState(true)
    const [gTheme, setGTheme] = useState<string>(defaultTheme ? defaultTheme : LIGHT)
    const onCollapsed = () => {
        setCollapsed(!collapsed)
    }

    const onThemeToggle = (checked: boolean) => {
        const currentTheme = checked ? DARK : LIGHT
        setGTheme(currentTheme)
        onThemeChange?.(currentTheme)
    }

    const onGetThemeConfig = (theme: string): ThemeConfig => {
        const defaultThemeConfig = theme === DARK ? dark : light
        if (themeConfig) {
            return { ...defaultThemeConfig, ...themeConfig }
        }
        return defaultThemeConfig
    }
    return (
        <ConfigProvider theme={onGetThemeConfig(gTheme)}>
            <themeContext.Provider
                value={{
                    gTheme: gTheme,
                    sidebarCollapsed: collapsed,
                }}
            >
                <Layout className={`myfx-layout ${gTheme}`}>
                    <GSider key={i18n.language} collapsed={collapsed} theme={gTheme} onCollapsed={onCollapsed} {...rest} />
                    <Layout>
                        <GHeader loginName={loginName} theme={gTheme} onThemeToggle={onThemeToggle} />
                        <Content className={`myfx-content ${gTheme}`}>
                            <div className='main-div'>{children}</div>
                        </Content>
                        <Footer key={i18n.language} className='myfx-footer'>
                            {gTheme === LIGHT ? t('FOOTER_TEXT') : t('FOOTER_TEXT_DARK')}
                        </Footer>
                    </Layout>
                </Layout>
            </themeContext.Provider>
        </ConfigProvider>
    )
}

export default GLayout
