import Keycloak from 'keycloak-js'

export const initKeyCloak = (config: Keycloak.KeycloakConfig): Promise<Keycloak> => {
    const { clientId, realm, url } = config
    const keyCloakAuth = new Keycloak({
        clientId: clientId,
        realm: realm,
        url: url,
    })
    return new Promise((resolve, reject) => {
        keyCloakAuth
            .init({
                onLoad: 'login-required',
                checkLoginIframe: false,
            })
            .then(() => resolve(keyCloakAuth))
            .catch(() => reject())
    })
}
