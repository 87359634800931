import env from './config/env'

interface TabObject {
    guid: string
    timestamp: number
}

const localStorageTimeout = env.localStorageTimeout || 0
const localStorageResetInterval = env.localStorageResetInterval
const appName = env.keycloakClientId
let tabIntervalSet: NodeJS.Timer
let manualyUnload = false
const ItemType = {
    Session: 1,
    Local: 2,
}

export const isTabDuplicated = () => {
    const sessionGuid = getItem(ItemType.Session) || createGUID()
    setItem(ItemType.Session, sessionGuid)

    const val = getItem(ItemType.Local)
    const tabObj: TabObject | null = (val === '' ? null : JSON.parse(val)) || null
    const timeDiff = new Date().getTime() - localStorageTimeout

    if (tabObj === null || tabObj.timestamp < timeDiff || tabObj.guid === sessionGuid) {
        const setTabObj = () => {
            if (!manualyUnload) {
                const newTabObj: TabObject = {
                    guid: sessionGuid,
                    timestamp: new Date().getTime(),
                }
                setItem(ItemType.Local, JSON.stringify(newTabObj))
            }
        }

        setTabObj()
        tabIntervalSet = setInterval(setTabObj, localStorageResetInterval)
        window.onunload = () => {
            localStorage.removeItem(appName)
        }
        return false
    } else {
        return true
    }
}

export const clearTab = () => {
    manualyUnload = true
    clearInterval(tabIntervalSet)
    localStorage.removeItem(appName)
}

const getItem = (itemtype: number) => {
    let val = ''
    switch (itemtype) {
        case ItemType.Session:
            val = window.name
            break
        case ItemType.Local:
            val = localStorage.getItem(appName) || ''
            break
    }
    return val
}

const setItem = (itemtype: number, val: string) => {
    switch (itemtype) {
        case ItemType.Session:
            window.name = val
            break
        case ItemType.Local:
            localStorage.setItem(appName, val)
            // setCookie(appName, val, 365) // Setting cookie for 365 days
            break
    }
}

const createGUID = () => {
    const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}
