import { enviromentType } from '../types/enviromentType'

const NON_PROD = {
    keycloakUrl: 'https://auth-nonprod.m-daq.com/auth/',
}
const ALL = {
    keycloakClientId: window.location.hostname.includes('4xpro') ? 'myfx-pro' : 'myfx',
    refreshTokenInterval: 60000 * 4,
    countDownTimerCount: 120,
    sessionExpireTimer: 2,
    inActivityTimer: 13,
    localStorageTimeout: 150 * 1000,
    localStorageResetInterval: 5 * 100,
}
const DEV = {
    ...NON_PROD,
    ...ALL,
    baseURL: 'https://myfx-gateway-dev.m-daq.com',
    keycloakUrl: 'https://auth-nonprod-dev.m-daq.com/auth/',
    socketURL: 'https://myfx-gateway-dev.m-daq.com/ws-message',
    keycloakRealm: 'dev',
}
// do not commit URL change in LOCAL object because automation test requires localhost
const LOCAL = {
    ...NON_PROD,
    ...ALL,
    baseURL: 'http://localhost:10888',
    keycloakUrl: 'https://auth-nonprod-dev.m-daq.com/auth/',
    socketURL: 'http://localhost:10888/ws-message',
    keycloakRealm: 'dev',
}
const UAT = {
    ...NON_PROD,
    ...ALL,
    baseURL: 'https://myfx-gateway-uat.m-daq.com',
    socketURL: 'https://myfx-gateway-uat.m-daq.com/ws-message',
    keycloakRealm: 'uat',
}
const FUAT = {
    ...NON_PROD,
    ...ALL,
    baseURL: 'https://myfx-gateway-fuat.m-daq.com',
    socketURL: 'https://myfx-gateway-fuat.m-daq.com/ws-message',
    keycloakRealm: 'uat',
}
const PROD = {
    ...ALL,
    baseURL: 'https://myfx-gateway.m-daq.com',
    keycloakUrl: 'https://auth.m-daq.com/auth/',
    socketURL: 'https://myfx-gateway.m-daq.com/ws-message',
    keycloakRealm: 'prod',
}
const BCP = {
    ...ALL,
    baseURL: 'https://myfx-gateway-bcp.m-daq.com',
    keycloakUrl: 'https://auth.m-daq.com/auth/',
    socketURL: 'https://myfx-gateway-bcp.m-daq.com/ws-message',
    keycloakRealm: 'prod',
}

let environment: enviromentType

switch (window.location.hostname) {
    case 'fuat-myfx.m-daq.com':
    case 'fuat-4xpro.m-daq.com':
        environment = FUAT
        break
    case 'uat-myfx.m-daq.com':
    case 'uat-4xpro.m-daq.com':
        environment = UAT
        break
    case 'dev-myfx.m-daq.com':
    case 'dev-4xpro.m-daq.com':
        environment = DEV
        break
    case 'myfx.m-daq.com':
    case '4xpro.m-daq.com':
        environment = PROD
        break
    case 'myfx-bcp-jp.m-daq.com':
    case '4xpro-bcp-jp.m-daq.com':
        environment = BCP
        break
    default:
        environment = LOCAL
}

export default environment = {
    ...environment,
}
