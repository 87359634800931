import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import EN from './locales/en-us.json'
import CN from './locales/cn.json'

const resources = {
    'en-US': {
        translation: {
            ...EN,
        },
    },
    cn: {
        translation: {
            ...CN,
        },
    },
}

const localLanguage = localStorage.getItem('currentLanguage')
const currentLanguage = localLanguage ? localLanguage : 'en-US'

i18n.use(initReactI18next).init({
    resources: resources,
    lng: currentLanguage,
    fallbackLng: 'en-US',
    debug: false,
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
