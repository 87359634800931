import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface RateState {
    contraCcy: string
    transactionCcy: string
    ccyPair: string
    side: string
    valueDate: string
    indicative: boolean
    contraAmount?: string | null
    rate?: number | null
    error?: string | null
}

export const initialRateState: RateState = {
    contraCcy: '',
    transactionCcy: '',
    ccyPair: '',
    side: 'BUY',
    contraAmount: null,
    indicative: false,
    valueDate: '',
    rate: null,
    error: null,
}

export const RateSlice = createSlice({
    name: 'rate',
    initialState: initialRateState,
    reducers: {
        updateRate: (state, action: PayloadAction<RateState>) => {
            state.contraCcy = action.payload.contraCcy
            state.ccyPair = action.payload.ccyPair
            state.transactionCcy = action.payload.transactionCcy
            state.rate = action.payload.rate
            state.side = action.payload.side
            state.valueDate = action.payload.valueDate
            state.contraAmount = action.payload.contraAmount
            state.indicative = action.payload.indicative
            state.error = action.payload.error
        },
    },
})

export default RateSlice.reducer
export const { updateRate } = RateSlice.actions
