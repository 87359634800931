import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { kc_instance } from '../config/keycloak'

export interface TokenState {
    token: string | undefined
    refreshTokenTime: number
    refreshOrderListTime: number
}

const initialTokenState: TokenState = {
    token: kc_instance?.token,
    refreshTokenTime: 0,
    refreshOrderListTime: 0,
}

export const TokenSlice = createSlice({
    name: 'token',
    initialState: initialTokenState,
    reducers: {
        updateRefreshTokenTime: (state, action: PayloadAction<number>) => {
            state.refreshTokenTime = action.payload
        },
        updateTokenValue: (state, action: PayloadAction<string | undefined>) => {
            state.token = action.payload
        },
        updateOrderListTime: (state, action: PayloadAction<number>) => {
            state.refreshOrderListTime = action.payload
        },
    },
})

export default TokenSlice.reducer
export const { updateRefreshTokenTime, updateTokenValue } = TokenSlice.actions
