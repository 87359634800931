import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './app'
import Keycloak from 'keycloak-js'
import env from './config/env'
import { initKeyCloak } from './keycloak-initializer'
import { setKeycloak, kcInstancelogout } from './config/keycloak'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from './store/store'

const root = createRoot(document.getElementById('root') as HTMLElement)

initKeyCloak({
    url: env.keycloakUrl,
    realm: env.keycloakRealm,
    clientId: env.keycloakClientId,
})
    .then((keyCloakInstance: Keycloak) => {
        setKeycloak(keyCloakInstance)
        if (localStorage.getItem('isSessionExpired') === 'yes') {
            kcInstancelogout()
        } else {
            root.render(
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>,
            )
        }
    })
    .catch(error => console.error(error))
